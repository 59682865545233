const staggerChildren = 0.07;
const delayChildren = 0.05;
const stiffness = 1000;
const velocity = -1;
const delay = 0.05;

export const variants = {
    open: {
        transition: { staggerChildren, delayChildren },
        opacity: '1'
    },
    closed: {
        transition: { staggerChildren, delayChildren },
        opacity: '0'
    }
};
export const variants_menuItemContainer = {
    open: {
        display: 'block',
        opacity: 1,
        transition: {
            delay,
            stiffness,
            velocity,
            staggerChildren
        }
    },
    closed: {
        opacity: 0,
        display: 'none',
        transition: {
            delay,
            stiffness,
            velocity,
            staggerChildren,
            staggerDirection: -1
        }
    }
};

export const variants_menuItem = {
    open: {
        opacity: 1
    },
    closed: {
        opacity: 0
    }
};
